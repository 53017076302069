import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService {
  private secretKey = 'Jc$F1nanc1Al&'; // Cambia esta clave por una segura

  constructor() {}

  /**
   * Guardar datos encriptados en LocalStorage
   * @param key Clave del dato
   * @param value Valor del dato
   */
  setItem(key: string, value: any): void {
    try {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
      localStorage.setItem(key, encryptedData);
    } catch (error) {
      console.error('Error al encriptar los datos:', error);
    }
  }

  /**
   * Obtener datos desencriptados desde LocalStorage
   * @param key Clave del dato
   * @returns Valor desencriptado o null si no existe
   */
  getItem<T>(key: string): T | null {
    try {
      const encryptedData = localStorage.getItem(key);
      if (!encryptedData) {
        return null;
      }
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.secretKey).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData) as T;
    } catch (error) {
      console.error('Error al desencriptar los datos:', error);
      return null;
    }
  }

  /**
   * Eliminar un dato del LocalStorage
   * @param key Clave del dato
   */
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error al eliminar el dato:', error);
    }
  }

  /**
   * Limpiar todo el LocalStorage
   */
  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error al limpiar el LocalStorage:', error);
    }
  }
}
